import React, { Component } from 'react'



class App extends Component {
  render() {
    return (
      <div className="center">
        <div class='spinner-border text-success' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      </div>
    )
  }
}

export default App
