import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
  
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";



    // the show/hide functions are passed as props
    ReactDOM.render(
      <App />,
      document.getElementById("root")
    )
